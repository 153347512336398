import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function MastersQuotes() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<p>
					NYFW: China Day is co-founded by the American Fashion Designers
					Association (CFDA) in conjunction with Suntchi in 2018 to showcase the
					latest collections of representative Chinese designers and brands, in
					helping to promote Chinese designers and brands on the stage of New
					York Fashion Week and the US market. China Day is a platform for
					concentrating the power of Chinese contemporary design and connecting
					Chinese fashion to the US market.
				</p>{" "}
				<p>
					纽约时装周天猫中国日（Tmall China
					Day）”由CFDA（美国时装设计师协会）联合阿里巴巴旗下的天猫和CFDA中国独家战略合作伙伴迅驰时尚（Suntchi）共同打造，展示来自中国优秀时尚品牌的最新系列，帮助中国设计师和品牌进入纽约时装周的舞台和美国市场。2x4北京团队受邀为《中国日》设计了视觉形象及延展应用系统。Logo以“中国日”三个中文字体为主视觉元素，通过拉伸延展可以适应于不同尺度的二维或者三维场景，为整套系统的应用提供了无限可能。英文全称叠加在图形Logo之上，相辅相成，CHINA
					DAY中NY的上下对应与纽约的缩写不谋而合。
				</p>
				<figure>
					<figcaption></figcaption>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/masters-quotes/1.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images/masters-quotes/2.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images/masters-quotes/3.JPG")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						img
						src={require("../images/masters-quotes/4.JPG")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(5);
						}}
						src={require("../images/masters-quotes/5.JPG")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(6);
						}}
						src={require("../images/masters-quotes/6.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						src={require("../images/masters-quotes/7.JPG")}
						alt=""
					></img>
				</figure>{" "}
				<p>Credits</p>
				<p>
					Creative Director: Xin Jing <br />
					At{" "}
					<a href="https://2x4.org/" target="_blank" rel="noreferrer">
						2x4
					</a>
					, Beijing
				</p>
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/masters-quotes/1.jpg"),
						require("../images/masters-quotes/2.jpg"),
						require("../images/masters-quotes/3.JPG"),
						require("../images/masters-quotes/4.JPG"),
						require("../images/masters-quotes/5.JPG"),
						require("../images/masters-quotes/6.jpg"),
						require("../images/masters-quotes/7.JPG"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default MastersQuotes;
