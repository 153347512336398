import React from "react";
import HomeNav from "./HomeNav";

function PradaRongZhaiLogoAnimation() {
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<figure
					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop: "5%",
					}}
				>
					<figcaption></figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752035105637&bvid=BV1jcabemEk9&cid=500001609288046&p=1&autoplay=0"						height="100%"
						frameborder="0"
						title="Prada RongZhai Logo Animation"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>{" "}
				<p>
					Actually I only did the animation, just visit{" "}
					<a
						href="https://2x4.org/work/prada-rong-zhai/"
						target="_blank"
						rel="noreferrer"
					>
						2x4
					</a>{" "}
					if you want to learn more about the whole project.
				</p>
			</div>{" "}
		</div>
	);
}

export default PradaRongZhaiLogoAnimation;
