import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function Vivo() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<figure 					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop:"5%",
					}}>
					<figcaption></figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752018393201&bvid=BV1iAabejE5v&cid=500001609286168&p=1&autoplay=0"						width="100%"
						height="100%"
						frameborder="0"
						title="Vivo"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>{" "}
				<figure>
					<figcaption></figcaption>
					<img 						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}} src={require("../images/vivo/1.gif")} alt=""></img>
				</figure>{" "}
				<figure 					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop:"5%",
					}}>
					<figcaption></figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752018329482&bvid=BV1LAabejEzk&cid=500001609286345&p=1&autoplay=0"						width="100%"
						height="100%"
						frameborder="0"
						title="Vivo 2"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>{" "}
				<figure 					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop:"5%",
					}}>
					<figcaption></figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752018327728&bvid=BV1LAabejEye&cid=500001609286460&p=1&autoplay=0"						width="100%"
						height="100%"
						frameborder="0"
						title="Vivo 3"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>{" "}
				<figure>
					<figcaption></figcaption>
					<img 						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}} src={require("../images/vivo/2.gif")} alt=""></img>
				</figure>{" "}
				<figure 					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop:"5%",
					}}>
					<figcaption></figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752035105027&bvid=BV1jcabemEeh&cid=500001609287327&p=1&autoplay=0" 						height="100%"
						frameborder="0"
						title="Vivo 4"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>{" "}
				<figure 					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop:"5%",
					}}>
					<figcaption></figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752035103255&bvid=BV17cabemELw&cid=500001609287611&p=1&autoplay=0"						height="100%"
						frameborder="0"
						title="Vivo 5"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>{" "}
				<figure 					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop:"5%",
					}}>
					<figcaption></figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752035105079&bvid=BV1jcabemEeQ&cid=500001609287682&p=1&autoplay=0"						height="100%"
						frameborder="0"
						title="Vivo 6"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>{" "}
				<figure 					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop:"5%",
					}}>
					<figcaption></figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752035105358&bvid=BV1jcabemEq2&cid=500001609287890&p=1&autoplay=0" 						height="100%"
						frameborder="0"
						title="Vivo 7"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>{" "}
				<p>
					完整视频请转至{" "}
					<a
						href="https://www.bilibili.com/video/BV1HF411i7NB/"
						target="_blank"
						rel="noreferrer"
					>
						vivo12 产品经理有话说{" "}
					</a>
				</p>
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/vivo/1.gif"),
						require("../images/vivo/2.gif"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default Vivo;
