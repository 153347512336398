import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "./NewGirl.scss";

function NewGirl() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/new-girl/1.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images/new-girl/2.gif")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					From feminism, LGBTQI, sexuality, life style to environmental
					protection, New Girl is a video channel which covers a wide range of
					inspiring topics. We encourage every individual to be a New Girl or
					New Woman who is honest, independent and free.
				</p>
				<p>
					<a
						href="https://space.bilibili.com/98608863?spm_id_from=333.788.b_765f7570696e666f.2"
						target="_blank"
						rel="noreferrer"
					>
						「绝对是个妞」
					</a>
					是个从女性视角看世界的媒体。旨在从日常生活到公共议题给更多女性带来启发，给我们每个人更多勇气，去做真实、独立和自由的
					New Girl 和 New Women.
				</p>
				<figure>
					<figcaption>Logo</figcaption>

					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images/new-girl/3.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						src={require("../images/new-girl/4.jpg")}
						alt=""
					></img>
				</figure>{" "}
				<figure>
					<figcaption>Icon</figcaption>

					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(5);
						}}
						src={require("../images/new-girl/5.jpg")}
						alt=""
					></img>
				</figure>{" "}
				<figure
					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop: "5%",
					}}
				>
					<figcaption>Motion Graphic</figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752001550593&bvid=BV1FvabesE2M&cid=500001609282483&p=1&autoplay=0"						width="100%"
						height="100%"
						frameborder="0"
						title="NewGirl"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
					{/* <figcaption>
						{" "}
						Go to{" "}
						<a
							href="https://vimeo.com/367633406"
							target="_blank"
							rel="noreferrer"
						>
							Vimeo
						</a>{" "}
						for higher quality video
					</figcaption> */}
				</figure>{" "}
				<figure>
					<figcaption>Tag</figcaption>

					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(6);
						}}
						src={require("../images/new-girl/6.jpg")}
						alt=""
					></img>
				</figure>{" "}
				<figure>
					<figcaption>Title</figcaption>

					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						src={require("../images/new-girl/7.jpg")}
						alt=""
					></img>
				</figure>{" "}
				<figure>
					<figcaption>Cover</figcaption>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(8);
						}}
						src={require("../images/new-girl/8.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(9);
						}}
						src={require("../images/new-girl/9.jpg")}
						alt=""
					></img>{" "}
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(10);
						}}
						src={require("../images/new-girl/10.jpg")}
						alt=""
					></img>{" "}
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(11);
						}}
						src={require("../images/new-girl/11.jpg")}
						alt=""
					></img>{" "}
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(12);
						}}
						src={require("../images/new-girl/12.jpg")}
						alt=""
					></img>{" "}
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(13);
						}}
						src={require("../images/new-girl/13.jpg")}
						alt=""
					></img>{" "}
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(14);
						}}
						src={require("../images/new-girl/14.jpg")}
						alt=""
					></img>{" "}
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(15);
						}}
						src={require("../images/new-girl/15.jpg")}
						alt=""
					></img>{" "}
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(16);
						}}
						src={require("../images/new-girl/16.jpg")}
						alt=""
					></img>{" "}
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(17);
						}}
						src={require("../images/new-girl/17.jpg")}
						alt=""
					></img>
				</figure>{" "}
				<p>Previous Version</p>
				<p>
					Budongai <br />
					<a
						href="https://space.bilibili.com/21065776"
						target="_blank"
						rel="noreferrer"
					>
						微在不懂爱
					</a>{" "}
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(18);
						}}
						src={require("../images/new-girl/18.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(19);
						}}
						src={require("../images/new-girl/19.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(20);
						}}
						src={require("../images/new-girl/20.jpg")}
						alt=""
					></img>
					<div 					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop:"5%",
					}}>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752001550664&bvid=BV1FvabesEyR&cid=500001609283786&p=1&autoplay=0"						width="100%"
						height="100%"
						frameborder="0"
						title="NewGirl 2"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe></div>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					Youzhong Girl
					<br />
					<a
						href="https://space.bilibili.com/21065776"
						target="_blank"
						rel="noreferrer"
					>
						有种girl
					</a>{" "}
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(21);
						}}
						src={require("../images/new-girl/21.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(22);
						}}
						src={require("../images/new-girl/22.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(23);
						}}
						src={require("../images/new-girl/23.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(24);
						}}
						src={require("../images/new-girl/24.jpg")}
						alt=""
					></img>
					<div
						style={{
							position: "relative",
							padding: "30% 45%",
							marginTop: "5%",
						}}
					>
						<iframe
							style={{
								position: "absolute",
								width: "100%",
								height: "100%",
								left: 0,
								top: 0,
							}}
							onContextMenu={(e) => e.preventDefault()}
							src="//player.bilibili.com/player.html?isOutside=true&aid=112752001550753&bvid=BV1FvabesEyb&cid=500001609284624&p=1&autoplay=0" 							width="100%"
							height="100%"
							frameborder="0"
							title="NewGirl 3"
							controls={true}
							controlsList="nodownload"
							webkitallowfullscreen
							mozallowfullscreen
							allowfullscreen
						></iframe>
					</div>
					<figcaption></figcaption>
				</figure>{" "}
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/new-girl/1.gif"),
						require("../images/new-girl/2.gif"),
						require("../images/new-girl/3.jpg"),
						require("../images/new-girl/4.jpg"),
						require("../images/new-girl/5.jpg"),
						require("../images/new-girl/6.jpg"),
						require("../images/new-girl/7.jpg"),
						require("../images/new-girl/8.jpg"),
						require("../images/new-girl/9.jpg"),
						require("../images/new-girl/10.jpg"),
						require("../images/new-girl/11.jpg"),
						require("../images/new-girl/12.jpg"),
						require("../images/new-girl/13.jpg"),
						require("../images/new-girl/14.jpg"),
						require("../images/new-girl/15.jpg"),
						require("../images/new-girl/16.jpg"),
						require("../images/new-girl/17.jpg"),
						require("../images/new-girl/18.gif"),
						require("../images/new-girl/19.gif"),
						require("../images/new-girl/20.jpg"),
						require("../images/new-girl/21.jpg"),
						require("../images/new-girl/22.jpg"),
						require("../images/new-girl/23.jpg"),
						require("../images/new-girl/24.jpg"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default NewGirl;
