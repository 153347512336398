import "./InTheMountain.scss";
import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function InTheMountain() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<figure
					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop:"5%",
					}}
				>
					{/* <video
						onContextMenu={(e) => e.preventDefault()}
						src="https://res.cloudinary.com/dfp56ti55/video/upload/v1698721545/1.%E5%98%8E%E5%8E%9F%E7%BA%AA%E5%BD%95%E7%89%87222_compressed_ht89rb.mp4"
						width="100%"
						controls={true}
						controlsList="nodownload"
					>
						Your browser does not support the video tag.
					</video> */}
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=569304396&bvid=BV1Hv4y1V7u7&cid=1077026460&p=1&autoplay=0"
						width="100%"
						height="100%"
						frameborder="0"
						title="嘎原纪录片"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
					{/* <iframe
						style={{ position: "reletive", width: "100%", height: "100%", left: 0, top: 0, }}
						src="https://player.bilibili.com/player.html?aid=569304396&bvid=BV1Hv4y1V7u7&cid=1077026460&p=1&high_quality=1"
						border="0"
						frameborder="no"
						framespacing="0"
						allowfullscreen="true"
					>
						{" "}
					</iframe> */}
					<figcaption>《嘎原》纪录片</figcaption>
				</figure>{" "}
				<p>
					嘎原是闽东山区的一个小村子，是我长大的地方。我通过手持设备摄影和漫画、动画的方式记录嘎原这个小世界。除了日常所见的乡村的静谧美好、生活的自给自足，我更希望关于嘎原的个体叙述能够提供更直观、生动的观察世界的视角，呈现出这片土地上的自然、生命、成长和人性。
				</p>
				<p>
					纪录片开头一分钟的视频混剪呈现的是嘎原的一些瞬间和切面，而后的两个短故事讲述了分别是“我因为疫情返乡陪伴祖辈”和“回忆儿时在阿姨和野果的陪伴下长大”。旁白所用的闽东方言在村子里依然是主要用语，我希望这则短片所面向的观众也包括村民们，让他们看到自己稀松平常的农事生活是值得被讲述的。
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/in-the-mountain/cd212d7e-ce67-478e-b01d-0d9f7932eae7_rw_1920.jpg")}
						alt=""
					></img>
					<figcaption>
					纪录片《嘎原》，提名荷兰EKP Excellence in Research Award 2021、 展映于Dutch Design Week、北京国际电影短片联展、HiShort厦门短片周等
					</figcaption>
				</figure>
				<p>
					嘎原就像一个微缩的世界，人与自然的互动、人与人的关系就像生态系统本身一样千丝万缕地相连着。我想呈现我对自己成长的土地抽丝剥茧式的细微观察，用故事的力量通过生活化而非教条式的叙述与观众/读者共鸣。自然和性别将会故事中触及的两大面向，也是我重返嘎原找到的关于更大的世界的答案，我想把它像山村的雾一样融入到我的故事里，自然、诚实地讲述。
				</p>
				<p>
					My project 'Gayuan, Life in the Mountains' is a series of documentary
					formed by multiple media including photos, videos, illustrations and
					animations. It presents my experience of growing up in the rural
					village Gayuan in southeast China, where people still maintain the
					traditional farming life which had been formed through thousands years
					of history. Besides knowledge and technique of farming, it’s also
					about indigenous understanding of the Nature we inhabit and rely on.
				</p>
				<p>
					By documenting the rich culture and ancient philosophy in the Gayuan,
					and narrating them in a personal experience-based way, I’m trying to
					share a methodology of how we can objectify, problematize and situate
					ourselves within the Nature shared by other (human/non-human,
					living/non-living) beings, observe our amazingly pluralistic and
					intense connections with with the surroundings, reflect on what we are
					as human beings and hopefully build up a map of 'human ecosystem'
					after.
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images/in-the-mountain/6cb87736-e450-4145-bb61-99c9248c883b_rw_1920.png")}
						alt=""
					></img>
					<figcaption>
						《嘎原》在硕士毕业展、 荷兰设计周、北京国际短片电影节
					</figcaption>
				</figure>
				<p>
					The local language I'm speaking in the films is still the first
					language for villagers, who are an important part of my audience. The
					way of life has already started vanishing under the rapid development
					of modernization, before it has a chance to be recorded and share. The
					history will been gone with the elder generation as they are passing
					away, and the younger generation are migrating to the cities.
				</p>
				<p>
					Most villagers think their farming life to be valueless according to
					my daily conversation with them. I want to honor them through the
					video stories, and make them feel proud of the beauty of Gayuan and
					their hardworking in the mountains. To people who admire and fantasize
					rural life, I want to show them the reality I’ve experienced in Gayuan
					which can be very different and sometimes cruel. To those who are not
					familiar with it, I want to show them a different aspect and
					possibility of life.
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images/in-the-mountain/b8cfe1e5-6864-49af-ba9a-0a84fcd03945_rw_1920.jpg")}
						alt=""
					></img>
					<figcaption>
						在嘎原长期记录超过400种本土物种，可转至我的{" "}
						<a
							target="_blank"
							href="https://www.inaturalist.org/people/elbowxy"
							rel="noreferrer"
						>
							iNaturalist 页面
						</a>
						了解更多
					</figcaption>
				</figure>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						src={require("../images/in-the-mountain/38cf7dbe-171e-46d6-8940-ecc96af9e082_rw_1920.png")}
						alt=""
					></img>
					<figcaption>
						长期调研、参与家乡农事、民俗，这些活动与自然有着细密的交叠
					</figcaption>
				</figure>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(5);
						}}
						src={require("../images/in-the-mountain/1c4d43a8-6048-41aa-b49e-5f3837cdc806_rw_1920.png")}
						alt=""
					></img>
					<figcaption>
						我的嘎原物种记录地图 、“厕所改革”水质调查小展览 、人与自然的关系模型
					</figcaption>
				</figure>
				<p>
					嘎原的故事目前还仅仅是一个开始，我将会用漫画的方式继续讲述这个小世界里我所珍视的一切
					——
					在嘎原这片与自然母亲无比亲近地交织在一起的土地上，人们用汗和血泪浇灌、收获着如野草般顽强、质朴、闪亮的生命。
				</p>
				<figure className="flexImageContainer">
					<figcaption>
						《嘎原》漫画第一话 <br />
						每页阅读顺序从右往左
					</figcaption>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(6);
						}}
						src={require("../images/in-the-mountain/1.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						src={require("../images/in-the-mountain/2.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(8);
						}}
						src={require("../images/in-the-mountain/3.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(9);
						}}
						src={require("../images/in-the-mountain/4.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(10);
						}}
						src={require("../images/in-the-mountain/5.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(11);
						}}
						src={require("../images/in-the-mountain/6.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(12);
						}}
						src={require("../images/in-the-mountain/7.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(13);
						}}
						src={require("../images/in-the-mountain/8.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(14);
						}}
						src={require("../images/in-the-mountain/9.jpg")}
						alt=""
					></img>
				</figure>
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/in-the-mountain/cd212d7e-ce67-478e-b01d-0d9f7932eae7_rw_1920.jpg"),
						require("../images/in-the-mountain/6cb87736-e450-4145-bb61-99c9248c883b_rw_1920.png"),
						require("../images/in-the-mountain/b8cfe1e5-6864-49af-ba9a-0a84fcd03945_rw_1920.jpg"),
						require("../images/in-the-mountain/38cf7dbe-171e-46d6-8940-ecc96af9e082_rw_1920.png"),
						require("../images/in-the-mountain/1c4d43a8-6048-41aa-b49e-5f3837cdc806_rw_1920.png"),
						require("../images/in-the-mountain/1.jpg"),
						require("../images/in-the-mountain/2.jpg"),
						require("../images/in-the-mountain/3.jpg"),
						require("../images/in-the-mountain/4.jpg"),
						require("../images/in-the-mountain/5.jpg"),
						require("../images/in-the-mountain/6.jpg"),
						require("../images/in-the-mountain/7.jpg"),
						require("../images/in-the-mountain/8.jpg"),
						require("../images/in-the-mountain/9.jpg"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default InTheMountain;
