import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "./PhysiologyOfLove.scss";

function PhysiologyOfLove() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}

	return (
		<div className="portfolio-page PhysiologyOfLove">
			<HomeNav />
			<div className="right-bar-content">
				<p>
					Toys / Prints / Animation <br />
					TOOLS Cinema 4D / After Effect / InDesign / Illustrator / Photoshop
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/physiology-of-love/1.jpg")}
						alt=""
					></img>
				</figure>
				<p>
					Why do we become emotional and crazy when falling in love? It seems
					hard to explain our behavior in affinity. Actually our mind and "soul"
					are parts of the bodies as well. It's all about organs and hormones
					doing stupid things inside our bodies.
				</p>
				<p>
					I created this project to explain 6 symptoms love brings after a large
					mount of research. It's also a summary of both my 4-years
					undergraduate studying career (I changed my major from Biology to
					Design) and personal life.
				</p>{" "}
				<p>
					关于爱情我们有太多疑惑，为此捏造出数不清的理论互相说服。它究竟有何种魔力让人精神混乱举止疯狂？一见钟情真实存在吗？情人眼里出西施只是一句调侃？莫名的压力感从何而来？…{" "}
					<br />
					我们常常把爱情归类为心灵上所需的情感依靠，但是我们也常忽略心与精神其实也算是我们身体的一部分。换而言之，这确实是可以通过生理学进行科学解释的
					— 爱情不过就是器官和激素在你的身体里卖蠢。
				</p>
				<figure
					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop: "5%",
					}}
				>
					<p>Animation</p>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=979102383&bvid=BV1V44y1p7p9&cid=508690384&autoplay=0" 						width="100%"
						height="100%"
						frameborder="0"
						title="PhysiologyOfLove"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
					{/* <figcaption>
						Go to{" "}
						<a
							href="https://vimeo.com/366004060"
							target="_blank"
							rel="noreferrer"
						>
							Vimeo
						</a>{" "}
						for higher quality video
					</figcaption> */}
				</figure>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images//physiology-of-love/2.jpg")}
						alt=""
					></img>
					<figcaption>{/* Toys, 3D Printing */}</figcaption>
				</figure>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images//physiology-of-love/3.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						src={require("../images//physiology-of-love/4.jpg")}
						alt=""
					></img>
					<figcaption>Toys, 3D Printing</figcaption>
				</figure>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(5);
						}}
						src={require("../images//physiology-of-love/5.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(6);
						}}
						src={require("../images//physiology-of-love/6.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						src={require("../images//physiology-of-love/7.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(8);
						}}
						src={require("../images//physiology-of-love/8.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(9);
						}}
						src={require("../images//physiology-of-love/9.jpg")}
						alt=""
					></img>
					<figcaption>Prints</figcaption>
				</figure>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(10);
						}}
						src={require("../images//physiology-of-love/10.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(11);
						}}
						src={require("../images//physiology-of-love/11.jpg")}
						alt=""
					></img>
					<figcaption>Sketch & Modeling</figcaption>
				</figure>
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/physiology-of-love/1.jpg"),
						require("../images/physiology-of-love/2.jpg"),
						require("../images/physiology-of-love/3.jpg"),
						require("../images/physiology-of-love/4.jpg"),
						require("../images/physiology-of-love/5.jpg"),
						require("../images/physiology-of-love/6.jpg"),
						require("../images/physiology-of-love/7.jpg"),
						require("../images/physiology-of-love/8.jpg"),
						require("../images/physiology-of-love/9.jpg"),
						require("../images/physiology-of-love/10.jpg"),
						require("../images/physiology-of-love/11.jpg"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default PhysiologyOfLove;
