import logo from "./logo.svg";
import "./App.css";

// import MainContentPlainHTML from "./components/MainContentPlainHTML";
// import { Switch, Route } from "react-router-dom";
import Main from "./components/Main";
import Home from "./components/Home";
import ScrollToTop from "react-scroll-to-top";


function App() {
	
	return (
		<div className="App">
			<Main />
			<ScrollToTop smooth color="black" top="800" width="15" height="15" style={{ right: 20 }} />
		</div>
	);
}

export default App;
