import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function BookCoverDesign() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<p>
					把自己作为方法：与项飙谈话
					<br />
					项飙、吴琦 - 著<br />
					The Self as Method: In conversation with Biao Xiang
					<br />
					by Biao Xiang, Qi Wu
				</p>{" "}
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/book-cover-design/1.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images/book-cover-design/2.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					扫地出门：美国城市的贫穷与暴利
					<br />
					马修·德斯蒙德 - 著<br />
					Evicted: Poverty and Profit in the American City
					<br />
					by Matthew Desmond
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images/book-cover-design/3.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					单读
					<br />
					吴琦 - 编<br />
					OW Magazine
					<br />
					by Qi Wu
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						src={require("../images/book-cover-design/4.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(5);
						}}
						src={require("../images/book-cover-design/5.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					最危险的书：为乔伊斯的《尤利西斯》而战
					<br />
					The Most Dangerous Book: The Battle for James Joyce's Ulysses
					<br />
					by Kevin Birmingham
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(6);
						}}
						src={require("../images/book-cover-design/6.JPG")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						src={require("../images/book-cover-design/7.JPG")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(8);
						}}
						src={require("../images/book-cover-design/8.JPG")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					春之祭：第一次世界大战和现代的开端
					<br />
					莫德里斯·埃克斯坦斯 - 著<br />
					Rites of Spring: the Great War and the Birth of the Modern Age
					<br />
					by Modris Eksteins
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(9);
						}}
						src={require("../images/book-cover-design/9.jpeg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(10);
						}}
						src={require("../images/book-cover-design/10.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					佩拉宫的午夜：现代伊斯坦布尔的诞生
					<br />
					查尔斯·金 - 著<br />
					Midnight at the Pera Palace: the Birth of Modern Istanbul
					<br />
					By Charles King
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(11);
						}}
						src={require("../images/book-cover-design/11.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(12);
						}}
						src={require("../images/book-cover-design/12.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(13);
						}}
						src={require("../images/book-cover-design/13.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					单身女性生存录
					<br />
					丽贝卡·特雷斯特 - 著<br />
					All the Single Ladies
					<br />
					by Rebecca Traister <br />
					(未采用设计稿 design unpublished)
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(14);
						}}
						src={require("../images/book-cover-design/14.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/book-cover-design/1.jpg"),
						require("../images/book-cover-design/2.jpg"),
						require("../images/book-cover-design/3.jpg"),
						require("../images/book-cover-design/4.jpg"),
						require("../images/book-cover-design/5.jpg"),
						require("../images/book-cover-design/6.JPG"),
						require("../images/book-cover-design/7.JPG"),
						require("../images/book-cover-design/8.JPG"),
						require("../images/book-cover-design/9.jpeg"),
						require("../images/book-cover-design/10.jpg"),
						require("../images/book-cover-design/11.jpg"),
						require("../images/book-cover-design/12.jpg"),
						require("../images/book-cover-design/13.jpg"),
						require("../images/book-cover-design/14.jpg"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default BookCoverDesign;
