import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function TwooCuup() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<p>
					Located in Beijing, Twoo Cuup cafe creates shared experiences over a
					cup of coffee. 2x4 partnered with Twoo Cuup to celebrate the
					experience of gathering for coffee with an “in pairs” brand identity
					system. 2x4 led a comprehensive brand refresh, including strategy,
					naming, and a full design system. We implemented the “in pairs”
					concept through the brand language, environment, print materials,
					packaging, and digital.
				</p>{" "}
				<p>
					两杯两杯是2x4北京工作室与ttg团队紧密合作的一个全新嵌入式咖啡运营服务品牌，也是年轻人新生活方式探索者。通过提供嵌入式咖啡解决方案，与不同主体空间跨界联结，提供品质更好的精品咖啡、创造先锋内容与服务、实现更便捷的多店连锁。构建具有社区感、创造联结、多维互动的精品咖啡品牌。
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/twoo-cuup/1.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images/twoo-cuup/2.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images/twoo-cuup/3.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						src={require("../images/twoo-cuup/4.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(5);
						}}
						src={require("../images/twoo-cuup/5.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(6);
						}}
						src={require("../images/twoo-cuup/6.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						src={require("../images/twoo-cuup/7.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(8);
						}}
						src={require("../images/twoo-cuup/8.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(9);
						}}
						src={require("../images/twoo-cuup/9.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(10);
						}}
						src={require("../images/twoo-cuup/10.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>Credits</p>{" "}
				<p>
					Creative Director: Xin Jing, Fu Xilin <br />
					At{" "}
					<a href="https://2x4.org/" target="_blank" rel="noreferrer">
						2x4
					</a>
					, Beijing
				</p>
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/twoo-cuup/1.gif"),
						require("../images/twoo-cuup/2.gif"),
						require("../images/twoo-cuup/3.jpg"),
						require("../images/twoo-cuup/4.jpg"),
						require("../images/twoo-cuup/5.jpg"),
						require("../images/twoo-cuup/6.jpg"),
						require("../images/twoo-cuup/7.jpg"),
						require("../images/twoo-cuup/8.jpg"),
						require("../images/twoo-cuup/9.jpg"),
						require("../images/twoo-cuup/10.jpg"),
					]}
					slide={lightboxController.slide}
				/>
			</div>{" "}
		</div>
	);
}

export default TwooCuup;
