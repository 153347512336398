import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "./Illustration.scss";

function Illustration() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content Illustration">
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/illustration/1.jpg")}
						alt=""
					></img>
					<figcaption>{/* Sketch */}</figcaption>
				</figure>
				<p>
					阿蛴叫，荔枝红，
					<br />
					螓蚜叫，掼火笼。
					<br />
					燕来三月三，
					<br />
					燕去七月半。
					<br />
					年年辛苦缘何事，
					<br />
					明年世界复蜀番。
				</p>
				<p>（“蜀”是“一”的意思）</p>
				<p>
					这是一首福州童谣，最早在微博上看到非常受触动，在心里酝酿了许久，借去年纸现场举办的“一袋一城”设计展画了出来。设计师们一起为环保水洗纸袋设计外观和衍生海报，最后主办方会把展览售出作品所得收益全数公益捐赠。
				</p>
				<figure>
					<div className="imageWrapper">
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(2);
							}}
							src={require("../images/illustration/2.jpg")}
							alt=""
						></img>
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(3);
							}}
							src={require("../images/illustration/3.jpg")}
							alt=""
						></img>
					</div>

					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						src={require("../images/illustration/4.jpg")}
						alt=""
					></img>
					<div className="imageWrapper">
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(5);
							}}
							src={require("../images/illustration/5.jpg")}
							alt=""
						></img>
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(6);
							}}
							src={require("../images/illustration/6.jpg")}
							alt=""
						></img>
					</div>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						src={require("../images/illustration/7.jpg")}
						alt=""
					></img>
					<div className="imageWrapper">
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(8);
							}}
							className="flexAdjust"
							src={require("../images/illustration/8.png")}
							alt=""
						></img>
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(9);
							}}
							className="flexAdjust"
							src={require("../images/illustration/9.jpg")}
							alt=""
						></img>
					</div>

					<figcaption>{/* Sketch */}</figcaption>
				</figure>

				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/illustration/1.jpg"),
						require("../images/illustration/2.jpg"),
						require("../images/illustration/3.jpg"),
						require("../images/illustration/4.jpg"),
						require("../images/illustration/5.jpg"),
						require("../images/illustration/6.jpg"),
						require("../images/illustration/7.jpg"),
						require("../images/illustration/8.png"),
						require("../images/illustration/9.jpg"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default Illustration;
