import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './Home';
import PhysiologyOfLove from './PhysiologyOfLove';
import AwfulHuman from './AwfulHuman';
import BookCoverDesign from './BookCoverDesign';
import RedAquarium from './RedAquarium';
import Owspace from './Owspace';
import NewGirl from './NewGirl';
import MusicianGuidance from './MusicianGuidance';
import ThornbackBookstore from './ThornbackBookstore';
import TwooCuup from './TwooCuup';
import MastersQuotes from './MastersQuotes';
import PradaRongZhaiLogoAnimation from './PradaRongZhaiLogoAnimation';
import PradaCny2018 from './PradaCny2018';
import About from './About';
import MasterClassOfDadong from './MasterClassOfDadong';
import Illustration from './Illustration';
import BellaCiao from './BellaCiao';
import Vivo from './Vivo';
import InTheMountain from './InTheMountain';
const Main = () => {
  return (
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' element={ <Home/>}></Route>
      <Route exact path='/in-the-mountain' element={<InTheMountain />}></Route>
      <Route exact path='/physiology-of-love' element={<PhysiologyOfLove />}></Route>
      <Route exact path='/awful-human' element={<AwfulHuman/>}></Route>
      <Route exact path='/book-cover-design' element={<BookCoverDesign />}></Route>
      <Route exact path='/bella-ciao' element={<BellaCiao/>}></Route>
      <Route exact path='/red-aquarium' element={<RedAquarium />}></Route>
      <Route exact path='/illustration' element={<Illustration/>}></Route>
      <Route exact path='/owspace' element={<Owspace/>}></Route>
      <Route exact path='/new-girl' element={<NewGirl />}></Route>
      <Route exact path='/musician-guidance' element={<MusicianGuidance/>}></Route>
      <Route exact path='/thornback-bookstore' element={<ThornbackBookstore/>}></Route>
      <Route exact path='/twoo-cuup' element={<TwooCuup />}></Route>
      <Route exact path='/vivo' element={<Vivo/>}></Route>
      <Route exact path='/masters-quotes' element={<MastersQuotes />}></Route>
      <Route exact path='/MasterClassOfDadong' element={<MasterClassOfDadong/>}></Route>
      <Route exact path='/prada-rong-zhai-logo-animation' element={<PradaRongZhaiLogoAnimation/>}></Route>
      <Route exact path='/prada-cny-2018' element={<PradaCny2018/>}></Route>
      <Route exact path='/about' element={<About/>}></Route>
    </Routes>
  );
}

export default Main;