import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "./Owspace.scss";

function Owspace() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<p>Logo for OWSPACE Book Store & OWSPACE Products (English Letters)</p>
				<p>单向空间书店标识、产品标识的英文字母</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/owspace/1.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					Logo for Union of Bookstores
					<br />
					书店联盟标识
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images/owspace/bookstore.png")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					Visual Identity for the second One Way Street Book Award
					<br />
					第二届书店文学奖视觉系统
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images/owspace/shudian1.png")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						src={require("../images/owspace/shudian2.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(5);
						}}
						src={require("../images/owspace/shudian4.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					Visual Identity for the 7th One Way Street Book Award <br />
					第七届书店文学奖视觉系统
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(6);
						}}
						className="imgWrapper1"
						src={require("../images/owspace/qijie1.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						className="imgWrapper2"
						src={require("../images/owspace/qijie2.jpg")}
						alt=""
					></img>
					<div className="imgWrapper3">
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(8);
							}}
							src={require("../images/owspace/qijie3.png")}
							alt=""
						></img>
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(9);
							}}
							src={require("../images/owspace/qijie4.png")}
							alt=""
						></img>
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(10);
							}}
							src={require("../images/owspace/qijie5.png")}
							alt=""
						></img>
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(11);
							}}
							src={require("../images/owspace/qijie6.png")}
							alt=""
						></img>
					</div>
					<div className="imgWrapper4">
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(12);
							}}
							src={require("../images/owspace/qijie7.png")}
							alt=""
						></img>
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(13);
							}}
							src={require("../images/owspace/qijie8.png")}
							alt=""
						></img>
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(14);
							}}
							src={require("../images/owspace/qijie9.png")}
							alt=""
						></img>
						<img
							onContextMenu={(e) => e.preventDefault()}
							onClick={() => {
								openLightboxOnSlide(15);
							}}
							src={require("../images/owspace/qijie10.png")}
							alt=""
						></img>
					</div>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					Masters's Quotes in Metro
					<br />
					大师言地铁(北京)投放海报
				</p>
				<figure className="masterQuoteFigure">
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(16);
						}}
						src={require("../images/owspace/dashiyan1.jpeg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(17);
						}}
						src={require("../images/owspace/dashiyan2.jpeg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(18);
						}}
						src={require("../images/owspace/dashiyan3.jpeg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(19);
						}}
						src={require("../images/owspace/daashiyan4.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>Products</p>{" "}
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(20);
						}}
						src={require("../images/owspace/products.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					01 OW Calendar
					<br />
					单向历
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(21);
						}}
						src={require("../images/owspace/calendar1.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(22);
						}}
						src={require("../images/owspace/calendar2.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(23);
						}}
						src={require("../images/owspace/calendar3.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					02{" "}
					<a
						href="https://apps.apple.com/us/app/%25E5%258D%2595%25E5%2590%2591%25E5%258E%2586/id1140390095"
						target="_blank"
						rel="noreferrer"
					>
						OW Cal. App{" "}
					</a>{" "}
					<br />
					单向历 app​​​​ ​​​界面
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(24);
						}}
						src={require("../images/owspace/app1.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(25);
						}}
						src={require("../images/owspace/app2.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(26);
						}}
						src={require("../images/owspace/app3.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					Using AR function to scan certain pages of the desk calendar and watch
					videos we filmed for readers.
				</p>{" "}
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(27);
						}}
						src={require("../images/owspace/app4.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					03{" "}
					<a
						href="https://apps.apple.com/cn/app/%25E5%258D%2595%25E8%25AF%25BB-%25E4%25B8%2580%25E5%25BA%25A7%25E9%259A%258F%25E8%25BA%25AB%25E5%259B%25BE%25E4%25B9%25A6%25E9%25A6%2586/id1064902326"
						target="_blank"
						rel="noreferrer"
					>
						OW Mag. App{" "}
					</a>{" "}
					<br />
					单读 app 界面
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(28);
						}}
						src={require("../images/owspace/mag1.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(29);
						}}
						src={require("../images/owspace/mag2.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					04 Wall Calendar Series
					<br />
					墙历
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(30);
						}}
						src={require("../images/owspace/wall1.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(31);
						}}
						src={require("../images/owspace/wall2.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(32);
						}}
						src={require("../images/owspace/wall3.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(33);
						}}
						src={require("../images/owspace/wall4.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>05 OW Notebook Series</p>{" "}
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(34);
						}}
						src={require("../images/owspace/notebook.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>OW</p>{" "}
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(35);
						}}
						src={require("../images/owspace/ow1.JPG")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(36);
						}}
						src={require("../images/owspace/ow2.JPG")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(37);
						}}
						src={require("../images/owspace/ow3.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>DUO</p>{" "}
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(38);
						}}
						src={require("../images/owspace/duo1.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(39);
						}}
						src={require("../images/owspace/duo2.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(40);
						}}
						src={require("../images/owspace/duo3.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>Reminder</p>{" "}
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(41);
						}}
						src={require("../images/owspace/reminder1.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(42);
						}}
						src={require("../images/owspace/reminder2.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(43);
						}}
						src={require("../images/owspace/reminder3.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>Symphony</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(44);
						}}
						src={require("../images/owspace/symp.JPG")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>06 Paper Tape</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(45);
						}}
						src={require("../images/owspace/tape1.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(46);
						}}
						src={require("../images/owspace/tape2.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/owspace/1.jpg"),
						require("../images/owspace/bookstore.png"),
						require("../images/owspace/shudian1.png"),
						require("../images/owspace/shudian2.jpg"),
						require("../images/owspace/shudian4.jpg"),
						require("../images/owspace/qijie1.jpg"),
						require("../images/owspace/qijie2.jpg"),
						require("../images/owspace/qijie3.png"),
						require("../images/owspace/qijie4.png"),
						require("../images/owspace/qijie5.png"),
						require("../images/owspace/qijie6.png"),
						require("../images/owspace/qijie7.png"),
						require("../images/owspace/qijie8.png"),
						require("../images/owspace/qijie9.png"),
						require("../images/owspace/qijie10.png"),
						require("../images/owspace/dashiyan1.jpeg"),
						require("../images/owspace/dashiyan2.jpeg"),
						require("../images/owspace/dashiyan3.jpeg"),
						require("../images/owspace/daashiyan4.jpg"),
						require("../images/owspace/products.jpg"),
						require("../images/owspace/calendar1.jpg"),
						require("../images/owspace/calendar2.jpg"),
						require("../images/owspace/calendar3.jpg"),
						require("../images/owspace/app1.jpg"),
						require("../images/owspace/app2.jpg"),
						require("../images/owspace/app3.jpg"),
						require("../images/owspace/app4.jpg"),
						require("../images/owspace/mag1.jpg"),
						require("../images/owspace/mag2.jpg"),
						require("../images/owspace/wall1.jpg"),
						require("../images/owspace/wall2.jpg"),
						require("../images/owspace/wall3.jpg"),
						require("../images/owspace/wall4.jpg"),
						require("../images/owspace/notebook.jpg"),
						require("../images/owspace/ow1.JPG"),
						require("../images/owspace/ow2.JPG"),
						require("../images/owspace/ow3.jpg"),
						require("../images/owspace/duo1.jpg"),
						require("../images/owspace/duo2.jpg"),
						require("../images/owspace/duo3.jpg"),
						require("../images/owspace/reminder1.jpg"),
						require("../images/owspace/reminder2.jpg"),
						require("../images/owspace/reminder3.jpg"),
						require("../images/owspace/symp.JPG"),
						require("../images/owspace/tape1.jpg"),
						require("../images/owspace/tape2.jpg"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default Owspace;
