import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function MusicianGuidance() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<p>
					《音乐人进阶指南》是由网易云音乐打造的针对编曲初学者的网络课程。
					<br />
					我在该项目中负责节目视觉、动效设计，包括片头、片尾、预告片和包装模板设计。
					<br />
					Musician Guidance is a series of tutorials for beginners of music
					producing, which is led by Netease Cloud Music, one of the biggest
					music platform in mainland China.
				</p>
				<figure
					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop: "5%",
					}}
				>
					<figcaption>片头 Opening theme</figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752018328099&bvid=BV1LAabejEdS&cid=500001609285459&p=1&autoplay=0" 						width="100%"
						height="100%"
						frameborder="0"
						title="Musician Guidance"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>{" "}
				<figure
					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop: "5%",
					}}
				>
					<figcaption>预告片 一 Trailer 1</figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752018328140&bvid=BV1LAabejERu&cid=500001609285927&p=1&autoplay=0"						width="100%"
						height="100%"
						frameborder="0"
						title="Musician Guidance Trailer 1"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>{" "}
				<figure
					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop: "5%",
					}}
				>
					<figcaption>预告片 二 Trailer 2</figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752018328674&bvid=BV1LAabejEtL&cid=500001609286205&p=1&autoplay=0"						width="100%"
						height="100%"
						frameborder="0"
						title="Musician Guidance Trailer 2"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>{" "}
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/musician-guidance/1.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images/musician-guidance/2.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images/musician-guidance/3.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						src={require("../images/musician-guidance/4.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(5);
						}}
						src={require("../images/musician-guidance/5.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(6);
						}}
						src={require("../images/musician-guidance/6.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						src={require("../images/musician-guidance/7.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(8);
						}}
						src={require("../images/musician-guidance/8.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(9);
						}}
						src={require("../images/musician-guidance/9.gif")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/musician-guidance/1.gif"),
						require("../images/musician-guidance/2.gif"),
						require("../images/musician-guidance/3.gif"),
						require("../images/musician-guidance/4.gif"),
						require("../images/musician-guidance/5.gif"),
						require("../images/musician-guidance/6.gif"),
						require("../images/musician-guidance/7.gif"),
						require("../images/musician-guidance/8.gif"),
						require("../images/musician-guidance/9.gif"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default MusicianGuidance;
