import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function BellaCiao() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<figure
					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop: "5%",
					}}
				>
					{/* <video
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="https://onedrive.live.com/download?resid=E9CC4DE91ACCE2D%2117013&authkey=!ACXZpkwioolJ_TE"
						width="100%"
						height="100%"
						frameborder="0"
						title="BellaCiao"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></video> */}
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752001550023&bvid=BV1cvabesENd&cid=500001609284696&p=1&autoplay=0"
						width="100%"
						height="100%"
						frameborder="0"
						title="BellaCiao"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
					<figcaption></figcaption>
				</figure>
				<p>
					　　疫情中的几年，我们不断失去，不断告别，而
					2021年诗人胡续冬的离去，是尤为令人心碎的事件，因为过去他常常是那个率先点燃热情、驱散阴霾的人。
					<br />
					　　他爱具体的人，爱具体的植物和动物。他的学识并没有局限在书斋之中。对他而言，认识一颗蘑菇和认识一个词语同样重要。他的爱具有很大的能量场，所以即使他因为意外离开了这个世界，被他爱过的人，都还被笼罩在这个能量场之下，还在被这个能量场保护，也在其中学习爱。
					<br />
					　　该片为“单向街书店文学奖”制作的致敬影片。影片致敬胡续冬的重点，并非停留在他作为诗人的文学成就，或者作为北大教授的教学贡献；而是致敬他诚毫无保留的“爱的能力”。
					<br />
					　　胡子的离开对爱他的人来说，是整个世界动荡的一部分；同时胡子的离开，也在提醒着所有人，面对世界动荡的方法：用具体的方式，爱具体的人和事。
					（摘自豆瓣的影片简介）
				</p>
				<p>
					{" "}
					　
					《啊，朋友再见》，导演刘宽，我负责了片头动画的绘制和影片的视觉设计（虽然动画没有在最终版呈现）。
				</p>
				<figure
					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop: "5%",
					}}
				>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752018327813&bvid=BV1LAabejEDZ&cid=500001609285174&p=1&autoplay=0"
						width="100%"
						height="100%"
						frameborder="0"
						title="BellaCiao 2"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
					<figcaption></figcaption>
				</figure>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/bella-ciao/1.jpeg")}
						alt=""
					></img>
					<figcaption>{/* Sketch */}</figcaption>
				</figure>
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[require("../images/bella-ciao/1.jpeg")]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default BellaCiao;
