import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function MasterClassOfDadong() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/master-class-of-dadong/1.gif")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					烹饪不仅仅是做一道菜，而是结合你的想象力、创造力所进行的艺术表达。
					当代中国最具影响力的名厨、中国意境菜创始人“大董”分享艺术烹饪的创作思路，色彩搭配、味觉搭配与摆盘的基本原则以及一些简单实用的厨房烹饪小技巧。
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images/master-class-of-dadong/2.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images/master-class-of-dadong/3.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						src={require("../images/master-class-of-dadong/4.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(5);
						}}
						src={require("../images/master-class-of-dadong/5.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(6);
						}}
						src={require("../images/master-class-of-dadong/6.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						src={require("../images/master-class-of-dadong/7.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(8);
						}}
						src={require("../images/master-class-of-dadong/8.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(9);
						}}
						src={require("../images/master-class-of-dadong/9.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(10);
						}}
						src={require("../images/master-class-of-dadong/10.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(11);
						}}
						src={require("../images/master-class-of-dadong/11.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(12);
						}}
						src={require("../images/master-class-of-dadong/12.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(13);
						}}
						src={require("../images/master-class-of-dadong/13.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/master-class-of-dadong/1.gif"),
						require("../images/master-class-of-dadong/2.jpg"),
						require("../images/master-class-of-dadong/3.jpg"),
						require("../images/master-class-of-dadong/4.jpg"),
						require("../images/master-class-of-dadong/5.jpg"),
						require("../images/master-class-of-dadong/6.jpg"),
						require("../images/master-class-of-dadong/7.jpg"),
						require("../images/master-class-of-dadong/8.jpg"),
						require("../images/master-class-of-dadong/9.jpg"),
						require("../images/master-class-of-dadong/10.jpg"),
						require("../images/master-class-of-dadong/11.jpg"),
						require("../images/master-class-of-dadong/12.jpg"),
						require("../images/master-class-of-dadong/13.jpg"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default MasterClassOfDadong;
