import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./HomeImage.scss";

function HomeImage(props) {
	return (
		<div className={`imageWrapper homeImage ${props.isOpen ? "open" : ""}`}>
			<Link to="/in-the-mountain" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/1b5344d7-d054-4c6b-9e31-ba193ea20232_rwc_0x0x900x900x900.gif")}
					alt=""
					className="image-main"
				></img>

				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/7f603025-7ed4-4870-89cd-34fc354870b3_rwc_0x0x1200x1200x1200.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/physiology-of-love" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/63cd3576-96c8-4eda-8640-047f898074ec_rwc_0x0x2569x2569x2569.jpg")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/05df42c6-03f3-415a-8691-148af8130b88_rwc_0x0x797x797x797.jpeg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/awful-human" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/7d2404c5-8e78-429d-9228-127f50827083_car_1x1.gif")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/4377ff02-23a5-4104-b101-3f0e69b31b34_rwc_0x0x1080x1080x1080.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/red-aquarium" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/96752b5d-e6a7-4cd9-8167-f487fd09f141_rwc_0x0x1080x1080x1080.gif")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/78ad5841-1e19-439f-8c2b-4e61e89bc707_rwc_0x0x1200x1200x1200.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/new-girl" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/f70621dc-9c37-44f4-9d66-ea0cec1b4666_rwc_0x0x1080x1080x1080.gif")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/7e44d4e6-4446-4cde-9d27-f42fa970da4d_rwc_553x0x817x817x1280.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/thornback-bookstore" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/18ed31a7-f019-406b-a696-f749c6492fce_carw_1x1x1280.gif")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/4cc89e7d-ac32-48ee-9ce5-a5fe82825ed5_rwc_0x0x1050x1050x1050.jpeg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/book-cover-design" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/b5e065dd-1561-4dfe-a7cd-b875f8ad9f51_rwc_0x0x1076x1076x1076.jpg")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/37411fc2-0c74-4cd6-bba5-12ea3e4a2f36_rwc_0x0x1076x1076x1076.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/owspace" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/f27f1424-b0e7-4374-bea3-fd873fb18234_rwc_0x0x1200x1200x1200.jpg")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/4c580edb-6609-4ccc-87cf-92914f218e1f_rwc_0x0x2710x2710x1280.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/bella-ciao" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/a31c7be0-c750-4520-b561-07af7382dfbc_rwc_0x0x1280x1280x1280.jpg")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/01017b4b-e546-4b4a-8a56-04894a10b201_rwc_0x0x1430x1430x1430.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/illustration" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/f65c8301-becb-4504-9dea-92134431d937_rwc_0x0x1500x1500x1500.jpg")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/fd8f2815-e622-4e73-9fbd-8ba4b99d7409_rwc_0x0x1500x1500x1500.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/musician-guidance" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/6816b550-12c6-49a9-b2ed-7067dc6c08b8_rwc_0x0x1241x1241x1241.gif")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/6a095b0c-0bba-493e-af74-728effa2761c_rwc_0x0x799x799x799.gif")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/vivo" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/602e6f52-1643-4022-8502-97e207df1d04_rwc_0x0x1200x1200x1200.jpg")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/cddd9519-3f7d-4ce4-8f32-7966d49ae920_rwc_0x0x1000x1000x1000.gif")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/MasterClassOfDadong" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/0ee39459-d1a0-4a30-a2bb-98eee61e66dd_rwc_0x0x1080x1080x1080.jpg")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/b6f4b714-2203-41a0-bf1e-a416b533eaeb_rwc_0x0x1080x1080x1080.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/twoo-cuup" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/4ac3dc34-8aba-4a5b-a4e5-4e0d08a5649b_rwc_0x0x1280x1280x1280.gif")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/4457d5cf-341b-4b1d-ba47-dd5bc624866f_rwc_179x0x1140x1140x1280.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/prada-rong-zhai-logo-animation" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/911c656c-76e7-414d-83cd-e03501be74f5_rwc_0x0x1080x1080x1080.gif")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/5faed247-345e-4c3e-a0e3-bf428214e7de_rwc_0x0x1080x1080x1080.jpeg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/masters-quotes" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/e6f4bc1e-cabc-4ac4-9c90-ae6bbd87ebfa_rwc_0x0x1240x1240x1240.jpg")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/febc18a4-52b4-439d-ba5b-715cb357c6fc_rwc_0x0x1200x1200x1200.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>

			<Link to="/prada-cny-2018" className="figure">
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/4fc87b46-7d01-48c6-bfe4-10e126d20524_rwc_0x0x1000x1000x1000.gif")}
					alt=""
					className="image-main"
				></img>
				<img
					onContextMenu={(e) => e.preventDefault()}
					src={require("../images/home/c3f50714-ee40-40d9-a9a5-183459d4b437_rwc_0x0x1200x1200x1200.jpg")}
					alt=""
					className="image-hover"
				></img>
			</Link>
		</div>
	);
}

export default HomeImage;
