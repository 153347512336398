import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function PradaCny2018() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<p>
					Animation and series of advertisements design for Prada during Chinese
					Lunar New Year 2018. It's the year of dogs, so the story is about two
					robot dogs partying with four robots, which are the accessories of the
					new Prada handbags, in Prada Spirit pop up stores.
				</p>{" "}
				<p>
					2x4再度与Prada合作，为其品牌量身打造2018新春动画及系列推广。狗年主动画以Prada机械狗和四款机器人配饰为主角，以Prada
					Spirit限时店为原型场景，演绎出在Cahier手袋里面的一场欢乐派对。动画会投放在包括香港机场在内的多个线下界面以及微信、Instagram等多个社交媒体平台。
				</p>
				<figure 					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop:"5%",
					}}>
					<figcaption>Animation</figcaption>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=112752102214652&bvid=BV1JRateQEaR&cid=500001609296905&p=1"						height="100%"
						frameborder="0"
						title="PradaCNY2018"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
				</figure>
				<figure>
					<figcaption>Logo</figcaption>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/prada-cny-2018/1.jpg")}
						alt=""
					></img>
				</figure>{" "}
				<figure>
					<figcaption>Key Visual</figcaption>

					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images/prada-cny-2018/2.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images/prada-cny-2018/3.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						src={require("../images/prada-cny-2018/4.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(5);
						}}
						src={require("../images/prada-cny-2018/5.jpg")}
						alt=""
					></img>
				</figure>{" "}
				<figure>
					<figcaption>
						<a
							href="http://pradawechat.fortytwo.com.cn/Events/201801/Index.aspx?hmsr=userShare_En"
							target="_blank"
							rel="noreferrer"
						>
							H5 Interactive Page{" "}
						</a>{" "}
						(Open this link with your mobile phone)
					</figcaption>

					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(6);
						}}
						src={require("../images/prada-cny-2018/6.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						src={require("../images/prada-cny-2018/7.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(8);
						}}
						src={require("../images/prada-cny-2018/8.gif")}
						alt=""
					></img>
				</figure>{" "}
				<figure>
					<figcaption>
						Pop up stores <br />
						Beijing
					</figcaption>

					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(9);
						}}
						src={require("../images/prada-cny-2018/9.JPG")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(10);
						}}
						src={require("../images/prada-cny-2018/10.JPG")}
						alt=""
					></img>
				</figure>{" "}
				<figure>
					<figcaption>Shanghai</figcaption>

					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(11);
						}}
						src={require("../images/prada-cny-2018/11.JPG")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(12);
						}}
						src={require("../images/prada-cny-2018/12.JPG")}
						alt=""
					></img>
				</figure>{" "}
				<p>Credits:</p>
				<p>
					Animation Concept, Creative Director, Script: 2x4 Beijing / New York{" "}
					<br />
					Animating: 蜜果互动传播顾问（北京）有限公司
					<br /> Agency: 特赞 Tezign <br />
					BGM: 北京酷意时代艺术有限公司 / Arrangment John Towse
					<br />
				</p>
				<p>
					H5: Creative Director, Design: 2x4 Beijing <br />
					Coding: 42传播
				</p>
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/prada-cny-2018/1.jpg"),
						require("../images/prada-cny-2018/2.jpg"),
						require("../images/prada-cny-2018/3.jpg"),
						require("../images/prada-cny-2018/4.jpg"),
						require("../images/prada-cny-2018/5.jpg"),
						require("../images/prada-cny-2018/6.gif"),
						require("../images/prada-cny-2018/7.gif"),
						require("../images/prada-cny-2018/8.gif"),
						require("../images/prada-cny-2018/9.JPG"),
						require("../images/prada-cny-2018/10.JPG"),
						require("../images/prada-cny-2018/11.JPG"),
						require("../images/prada-cny-2018/12.JPG"),
					]}
					slide={lightboxController.slide}
				/>
			</div>{" "}
		</div>
	);
}

export default PradaCny2018;
