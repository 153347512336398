import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "./RedAquarium.scss";

function RedAquarium() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page redAquarium">
			<HomeNav />
			<div className="right-bar-content">
				<p>
					Stop Motion Animation (40", 12f/s) <br />
					TOOL Photoshop / After Effect{" "}
				</p>
				<figure 					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop:"5%",
					}}>
				<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=49199457&bvid=BV1cb411T7Us&cid=86158520&p=1&autoplay=0"						width="100%"
						height="100%"
						frameborder="0"
						title="RedAquarium"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
					{/* <figcaption>
						Go to{" "}
						<a
							href="https://vimeo.com/327881905"
							target="_blank"
							rel="noreferrer"
						>
							Vimeo
						</a>{" "}
						for higher quality video
					</figcaption> */}
				</figure>
				<p>
					The number of marine theme parks in China is growing rapidly like the
					economy of this country. Even in my hometown, a little county named
					Yongtai, a simple aquarium started running in the beginning of 2019.
					But what the audience remain unaware of is the entertaining
					performances actually do kill these animals.
				</p>
				<p>
					The context of the animation came from a documentary{" "}
					<a
						href="https://www.youtube.com/watch?v=XSgco9rbR8A"
						target="_blank"
						rel="noreferrer"
					>
						Caging The Ocean's Wild
					</a>{" "}
					about the dark side the aquarium industry in China.
				</p>
				<p>
					The sea animals are caged and treated cruelly, and even died during
					the performance because of overworking and internal organs twisting.
					There is no professional veterinary for marine mammals in any of these
					parks. Once they get sick, they will usually be fed with random pills,
					then be kept in a "dying room" and won't be fed anymore if they don't
					get better. After these animals died, staff in some parks just hide
					their bodies in freezers. But there is no law to forbidden animal
					cruelty in China.
				</p>
				<p>
					The only thing we can do is say no to animal performance, and do not
					visit marine theme parks anymore.
				</p>
				<p>
					海洋馆里的鲸豚表演到底是不是一种伤害？它们看着蛮开心的呀。你想不到的是，鲸豚只是天生长着笑脸，而海洋馆正是这些美丽生物的集中营！当其他国家都已经立法禁止鲸豚圈养，国内的海洋馆数量却还在迅猛增长，连我的小小家乡永泰县都来了一个。
				</p>
				<p>
					动画内容来自
					<a
						href="https://www.youtube.com/watch?v=XSgco9rbR8A"
						target="_blank"
						rel="noreferrer"
					>
						Caging The Ocean's Wild
					</a>
					，对我的震撼实在太大以至于不得不做些什么。
				</p>
				<p>
					视频里可以看到好多海洋馆甚至已经在训练虎鲸，只是碍于国际谴责不敢公开表演。可能近十年都不会有法律把泛滥的海洋馆统统关掉，我们能做的只有不要给海洋馆花一分钱。不要指望这种丧失人性的买卖、幽禁和虐待会给你家小孩带来任何教育意义，那只是海洋馆包装的谎言。随便一部海洋纪录片都更能展示海洋的奇妙和震撼。
				</p>
				<p>
					共情不只是人际之间的必须，我们对待其他生命更是如此。在精彩纷呈的自然世界人类根本微不足道，生命的美是因为自由。​​​​​​​
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/red-aquarium/1.png")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[require("../images/red-aquarium/1.png")]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default RedAquarium;
