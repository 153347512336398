import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function ThornbackBookstore() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/thornback-bookstore/1.gif")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images/thornback-bookstore/2.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<p>
					Thornback is an indie bookstore in Beijing with books, tea, home
					supplies, and function of a bazaar. In 18th-century American slang,
					thornback refers to unmarried women after the age of 26, and is used
					to mock them for their ugliness and weirdness. But for Yana as a
					female, the thornback means stubbornly pursuing a better world under
					imperfect reality.
				</p>
				<p>
					'I think the value of an independent store is to enrich a monotonous
					life mode based on some unreasonable "hobbies". We want to create a
					range of free choices in this space, so that people may instantly be
					free from the imprisonment of society." — Yana
				</p>
				<p>
					刺鱼是位于北京的一家集书籍、茶饮、居家、杂货市集于一体的独立店铺。
					<br />
					在18世纪美国的俚语中，刺鱼表示26碎岁以后未嫁的女子，用来嘲讽她们丑陋古怪。但对于作为女性的主理人Yana来说，刺鱼意味着在不完美的现实下倔强地追寻美好的世界。
				</p>
				<p>
					“我觉得独立店铺的价值就是按照自己的非合理性的一些“嗜好品”来丰富单一的生活模式。我们想在这个空间当中营造一种自由选择的范围，让人们或许在瞬间可以从社会的禁锢当中解脱出来。”
					— Yana
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images/thornback-bookstore/3.jpg")}
						alt=""
					></img>
					<figcaption></figcaption>
				</figure>{" "}
				<figure>
					<figcaption>Logos</figcaption>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(4);
						}}
						src={require("../images/thornback-bookstore/4.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(5);
						}}
						src={require("../images/thornback-bookstore/5.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(6);
						}}
						src={require("../images/thornback-bookstore/6.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(7);
						}}
						src={require("../images/thornback-bookstore/7.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(8);
						}}
						src={require("../images/thornback-bookstore/8.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(9);
						}}
						src={require("../images/thornback-bookstore/9.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(10);
						}}
						src={require("../images/thornback-bookstore/10.jpg")}
						alt=""
					></img>
				</figure>{" "}
				<figure>
					<figcaption>
						Examples of posters
						<br />
						海报示例
					</figcaption>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(11);
						}}
						src={require("../images/thornback-bookstore/11.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(12);
						}}
						src={require("../images/thornback-bookstore/12.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(13);
						}}
						src={require("../images/thornback-bookstore/13.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(14);
						}}
						src={require("../images/thornback-bookstore/14.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(15);
						}}
						src={require("../images/thornback-bookstore/15.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(16);
						}}
						src={require("../images/thornback-bookstore/16.jpg")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(17);
						}}
						src={require("../images/thornback-bookstore/17.jpg")}
						alt=""
					></img>
				</figure>{" "}
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/thornback-bookstore/1.gif"),
						require("../images/thornback-bookstore/2.jpg"),
						require("../images/thornback-bookstore/3.jpg"),
						require("../images/thornback-bookstore/4.jpg"),
						require("../images/thornback-bookstore/5.jpg"),
						require("../images/thornback-bookstore/6.jpg"),
						require("../images/thornback-bookstore/7.jpg"),
						require("../images/thornback-bookstore/8.jpg"),
						require("../images/thornback-bookstore/9.jpg"),
						require("../images/thornback-bookstore/10.jpg"),
						require("../images/thornback-bookstore/11.jpg"),
						require("../images/thornback-bookstore/12.jpg"),
						require("../images/thornback-bookstore/13.jpg"),
						require("../images/thornback-bookstore/14.jpg"),
						require("../images/thornback-bookstore/15.jpg"),
						require("../images/thornback-bookstore/16.jpg"),
						require("../images/thornback-bookstore/17.jpg"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default ThornbackBookstore;
