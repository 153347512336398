import HomeNav from "./HomeNav";
import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

function AwfulHuman() {
	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1,
	});

	function openLightboxOnSlide(number) {
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: number,
		});
	}
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<p>
					Stop Motion Animation (5'13", 24f/s) <br />
					TOOL Photoshop / After Effect{" "}
				</p>
				<figure
					style={{
						position: "relative",
						padding: "30% 45%",
						marginTop: "5%",
					}}
				>
					<iframe
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							left: 0,
							top: 0,
						}}
						onContextMenu={(e) => e.preventDefault()}
						src="//player.bilibili.com/player.html?isOutside=true&aid=49195893&bvid=BV1Fb411T7ys&cid=86151424&p=1&autoplay=0"
						width="100%"
						height="100%"
						frameborder="0"
						title="我不是什么好人"
						controls={true}
						controlsList="nodownload"
						webkitallowfullscreen
						mozallowfullscreen
						allowfullscreen
					></iframe>
					{/* <figcaption>
						Go to{" "}
						<a
							href="https://vimeo.com/317649829"
							target="_blank"
							rel="noreferrer"
						>
							Vimeo
						</a>{" "}
						for higher quality video
					</figcaption> */}
				</figure>
				<p>
					This animation introduces the importance of empathy, based on Zero
					Degrees of Empathy by Simon Baron- Cohen.
					<br />
					It's a project that comes from our daily life: It was the last two
					years when I found myself the same as people on social media who care
					too much about their appearances, and try so hard to act cool, funny
					and smart. We desire attention and love so bad but unwilling to give.
					Indeed, it's dangerous when individualism becomes the root of pop
					culture and get promoted automatically through the market and get
					planted in our brains. In the end, we only concern about showing off
					ourselves.
				</p>
				<p>
					魔幻大陆的魔幻秀天天都在上演，一个话题迅速被另一个话题掩盖。这种社会普遍的恶究竟是怎么回事呢？基于Simon
					Baron-Cohen的《恶的科学》，我尝试在这个动画里探讨共情于每个人作为社会成员的重要性。我们时下担忧的同性恋、女权、种族等问题，共情缺失都是其根本原因。甚至国际间的争锋相对，如果某些国家不妄图称霸世界，就不会发生。我们在互联网上或自恋或宣泄，沉浸在自己的泡泡里，是共情的缺失让一切沟通都变得无效。我们太想炫耀自己了。
				</p>
				<figure>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(1);
						}}
						src={require("../images/awful-human/1.png")}
						alt=""
					></img>
					<img
						onContextMenu={(e) => e.preventDefault()}
						// key={productIndex}
						onClick={() => {
							openLightboxOnSlide(2);
						}}
						src={require("../images/awful-human/2.png")}
						alt=""
					></img>

					<figcaption>{/* Sketch */}</figcaption>
				</figure>

				<figure>
					<figcaption>Sketch</figcaption>
					<img
						onContextMenu={(e) => e.preventDefault()}
						onClick={() => {
							openLightboxOnSlide(3);
						}}
						src={require("../images/awful-human/3.png")}
						alt=""
					></img>
				</figure>
				<FsLightbox
					toggler={lightboxController.toggler}
					sources={[
						require("../images/awful-human/1.png"),
						require("../images/awful-human/2.png"),
						require("../images/awful-human/3.png"),
					]}
					slide={lightboxController.slide}
				/>
			</div>
		</div>
	);
}

export default AwfulHuman;
