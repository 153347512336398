import React from "react";
import HomeImage from "./HomeImage";
import HomeNav from "./HomeNav";
import './Home.scss';

function Home() {
	return (
        <div className="home-page">
			<HomeNav />
			<HomeImage />
		</div>
	);
}

export default Home;
