import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./HomeNav.scss";

function HomeNav(props) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="left-bar-content">
			<header className="site-header">
				<div className="logo-wrap" data-hover-hint="logo">
					<div className="logo e2e-site-logo-text logo-image has-rollover  hoverable">
						<Link to="/" className="image-normal image-link">
							<img
								src={require("../images/home/22b4d3a1-d324-44a3-b9b8-dbd3baabdfde_rwc_0x0x301x201x4096.png")}
								alt="ye"
							/>
						</Link>
						<Link to="/" className="image-rollover image-link">
							<img
								src={require("../images/home/ce0865a2-d4cf-498b-82f1-fe785fcc4090_rwc_0x0x301x201x4096.jpeg")}
								alt="ye"
							/>
						</Link>
					</div>
				</div>
				<div className="hamburger-click-area js-hamburger" onClick={() => setIsOpen(!isOpen)}>
					<div className="hamburger">
					<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 12 16" height="1.5rem" width="1.5rem" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.41 9H.59C0 9 0 8.59 0 8c0-.59 0-1 .59-1H11.4c.59 0 .59.41.59 1 0 .59 0 1-.59 1h.01zm0-4H.59C0 5 0 4.59 0 4c0-.59 0-1 .59-1H11.4c.59 0 .59.41.59 1 0 .59 0 1-.59 1h.01zM.59 11H11.4c.59 0 .59.41.59 1 0 .59 0 1-.59 1H.59C0 13 0 12.59 0 12c0-.59 0-1 .59-1z"></path></svg>					</div>
				</div>
			</header>
			<nav data-hover-hint="nav" className={`nav ${isOpen ? 'open' : ''}`}>
				<ul className="group">
					<li className="project-title">
						<NavLink to="/in-the-mountain">/嘎原 in the Mountain</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/physiology-of-love">
							/爱情生理学 Physiology of Love
						</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/awful-human">/我不是什么好人 Awful Human</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/red-aquarium">/血族馆 Red Aquarium</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/new-girl">/绝对是个妞 New Girl</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/thornback-bookstore">
							/刺鱼书店 Thornback Bookstore
						</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/book-cover-design">
							/书籍封面 Book Cover Design
						</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/owspace">/单向空间 OWSPACE</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/bella-ciao">/啊朋友再见 Bella Ciao</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/illustration">/明年世界复蜀番 Things move on</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/musician-guidance">
							/音乐人进阶指南 Musician Guidance
						</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/vivo">
							/vivo S12 产品经理有话说 Animation for vivo
						</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/MasterClassOfDadong">
							/大师课：大董教艺术烹饪 Master Class: Dadong
						</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/twoo-cuup">/两杯两杯 Twoo Cuup Coffee</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/prada-rong-zhai-logo-animation">
							/荣宅标识动画 Prada Rong Zhai Logo Animation
						</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/masters-quotes">
							/纽约时装周中国日 NYFW China Day
						</NavLink>
					</li>
					<li className="project-title">
						<NavLink to="/prada-cny-2018">/Prada CNY 2018</NavLink>
					</li>
				</ul>
				<div className="about">
					<NavLink to="/about">About</NavLink>
				</div>
			</nav>
		</div>
	);
}

export default HomeNav;
