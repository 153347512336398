import React from "react";
import HomeNav from "./HomeNav";

function About() {
	return (
		<div className="portfolio-page">
			<HomeNav />
			<div className="right-bar-content">
				<p>
					许烨，来自福州市永泰县盖洋乡；本科就读于四川大学，期间从生命科学专业转至视觉传达设计专业；曾任职于单向空间、2x4设计工作室；后留学荷兰Master
					Institue of Visual Culture, Situated
					Design专业，期间回到家乡与祖辈一起生活，重新认识这片土地，观察、记录土地上发生的事物和生命，创作了独立纪录片《嘎原》，提名荷兰EKP
					Excellence in Research Award 2021、 展映于Dutch Design
					Week、北京国际电影短片联展、 釜山电影节Rolling花卷亚太线上青年影展等。
				</p>{" "}
				<p>
					我一直在努力创作处于“之间”的作品：不管是在不同学科之间还是不同人群之间，我想尝试填补其中裂痕，用浅显易读的作品抵达更多人，并产生共鸣和沟通。我相信这就是设计和故事所具备的力量。
				</p>{" "}
				<p>
					I'm Ye Xu, a visual designer from China, currently studying at AKV
					St.joost in the Netherlands. As an animator, illustrator, graphic and
					motion designer, I am still training myself to tell the stories of
					life better.
				</p>
				<p>
					Growing up in the mountains, I always see myself, including my work,
					as part of the forest.
				</p>{" "}
				<p>
					Like many other rural villages, people here inhabit themselves within
					the nature with other creatures, benefitting and respecting each
					other. It is where my world view originally came from. My further
					experience — including my education of science and literature, and
					living experience in modern cities domestically and abroad — all
					together makes me understand the value deeper:
				</p>{" "}
				<p>
					Everything has to follow its natural being, and every being is
					beautiful for its uniqueness.
				</p>{" "}
				<p>
					欢迎通过
					<a href="mailto:sharming@foxmail.com" target="_blank">
						邮箱
					</a>
					联系我，或关注我的
					<a href="https://weibo.com/xy390974953" target="_blank">
						微博
					</a>
					、
					<a href="https://instagram.com/oxuye/" target="_blank">
						instagram
					</a>{" "}
					<br />
					Feel free to contact me on{" "}
					<a href="mailto:sharming@foxmail.com" target="_blank">
						sharming@foxmail.com
					</a>
					&nbsp;or follow my{" "}
					<a href="https://weibo.com/xy390974953" target="_blank">
						weibo
					</a>{" "}
					and&nbsp;
					<a href="https://instagram.com/oxuye/" target="_blank">
						instagram
					</a>
					.
        </p>
        <figure>
					<figcaption></figcaption>
					<img src={require("../images/46ba8fd5-2560-4628-8df4-14a019aa3757_rw_1920.jpeg")} alt=""></img>
				</figure>{" "}
			</div>
		</div>
	);
}

export default About;
